import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

const Loading = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loading_container position-fixed d-flex align-items-center justify-content-center">
          <Spinner animation="border" role="status" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.loading.loading,
});

export default connect(mapStateToProps, null)(Loading);
