import * as Types from "../constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case Types.FETCH_USER_INFO:
      return {
        ...state,
        ...action.payload.userInfo,
      };
    case Types.UPDATE_USER_INFO:
      return {
        ...state,
        ...action.payload.updateUserInfo,
      };
    case Types.INIT_USER_INFO:
      return {};
    default:
      return state;
  }
};
