import { combineReducers } from "redux";
import products from "./product";
import cart from "./cart";
import quickView from "./quickView";
import productFilters from "./productFilters";
import confirm from "./confirm";
import modal from "./modal";
import loading from "./loading";
import baskets from "./baskets";
import alliance from "./alliance";
import banners from "./banners";
import dummyList from "./dummy";
import vp from "./userView";
import siteInfo from "./siteInfo";
import defaultModal from "./defaultModal";
import userInfo from "./userInfo";
import imgZoom from "./imgZoom";

const rootReducer = combineReducers({
  products,
  cart,
  quickView,
  productFilters,
  confirm,
  modal,
  loading,
  baskets,
  alliance,
  banners,
  dummyList,
  vp,
  siteInfo,
  defaultModal,
  userInfo,
  imgZoom,
});

export default rootReducer;
