const BASE_URL = "https://api.caretbio.com/api";
// const BASE_URL = "http://192.168.0.74:80/api";
const SFNZ_URL = "https://api.caretbio.com/api/sfnz/Sfnz";
const STORE_PRODUCTS_URL = "https://www.caretstore.co.kr/products/";
const SUPPORT_URL = "https://support.caretbio.com";
// const SUPPORT_URL = "http://192.168.0.74:3010";

const CHANNEL_STORE = "/store";
const CHANNEL_COMMON = "/common";
const API_MEMBER = "/Members";
const API_PRODUCTS = "/Products";
const API_SITEINFO = "/SiteInfo";
const API_CATEGORY = "/Category";
const API_BASKETS = "/Baskets";
const API_CERTIFICATION = "/Certification";
const API_FAVORITES = "/Favorites";
const API_ORDER = "/Order";
const API_BOARDS = "/Boards";
const API_ALLIANCE = "/Alliance";
const API_CORPORATION = "/Corporation";
const API_PERSON = "/Person";
const API_MEMBERADDRESS = "/MemberAddress";
const API_VISITS = "/Visits";
const API_DUMMY = "/DefaultArray";
const API_BANNERS = "/Banners";
const API_MAKECATEGORYS = "/MakeCategorys";
const API_PACKAGINGBASKET = "/PackagingBasket";
const API_PACKAGING = "/Packaging";
const API_PARTNERS = "/Partners";
const API_PORTFOLIO = "/Portfolio";
const API_SUBSCRIBE = "/Subscribe";
const API_DELIVERY = "/Delivery";
const API_RECEIPT = "/Receipt";
const API_BOARD = "/Board";
const API_POPUPS = "/Popups";
const API_GROUPBUYING = "/GroupBuying";
const API_FCM = "/Fcm";
const API_LOGS = "/Logs";
const API_ADLINKS = "/AdLinks";
const API_LICENSE = "/License";
const API_DOWNLOADS = "/Downloads";
const API_PRODUCTS_TEMP = "/Products_Temp";
const API_VOTE = "/Vote";
const API_SMALLPACKAGING = "/SmallPackaging";
const API_EVENTPAGES = "/EventPages";
const API_MARKETING = "/Marketing";
const API_COUPON = "/Coupon";
const API_FILES = "/files";
const API_COMMENT = "/Comment";

// BASE_URL + CHANNEL_NAME + API_NAME
const BASE_CHANNEL_MEMBER =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_MEMBER}`;

const BASE_CHANNEL_PRODUCTS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PRODUCTS}`;

const BASE_CHANNEL_SITEINFO =
  `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_SITEINFO}`;

const BASE_CHANNEL_CERTIFICATION =
  `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_CERTIFICATION}`;

const BASE_CHANNEL_CATEGORY =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_CATEGORY}`;

const BASE_CHANNEL_BASKETS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_BASKETS}`;

const BASE_CHANNEL_FAVORITES =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_FAVORITES}`;

const BASE_CHANNEL_ORDER = `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_ORDER}`;

const BASE_CHANNEL_BORADS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_BOARDS}`;

const BASE_CHANNEL_ALLIANCE =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_ALLIANCE}`;

const BASE_CHANNEL_CORPORATION =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_CORPORATION}`;

const BASE_CHANNEL_PERSON =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PERSON}`;

const BASE_CHANNEL_MEMBERADDRESS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_MEMBERADDRESS}`;

const BASE_CHANNEL_VISITS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_VISITS}`;

const BASE_CHANNEL_DUMMY = `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_DUMMY}`;

const BASE_CHANNEL_BANNERS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_BANNERS}`;

const BASE_CHANNEL_MAKECATEGORYS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_MAKECATEGORYS}`;

const BASE_CHANNEL_PACKAGINGBASKET =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PACKAGINGBASKET}`;

const BASE_CHANNEL_PACKAGING =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PACKAGING}`;

const SFNZ = `${SFNZ_URL}`;

const BASE_CHANNEL_PARTNERS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PARTNERS}`;

const BASE_CHANNEL_PORTFOLIO =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PORTFOLIO}`;

const BASE_CHANNEL_SUBSCRIBE =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_SUBSCRIBE}`;

const BASE_CHANNEL_DELIVERY =
  `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_DELIVERY}`;

const BASE_CHANNEL_RECEIPT =
  `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_RECEIPT}`;

// 제휴 문의 BOARD
const BASE_CM_BOARD = `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_BOARD}`;

const BASE_CHANNEL_POPUPS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_POPUPS}`;

const BASE_CHANNEL_GROUPBUYING =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_GROUPBUYING}`;

// FCM
const BASE_CHANNEL_FCM = `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_FCM}`;

// LOGs
const BASE_CHANNEL_LOGS = `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_LOGS}`;

//API_ADLINKS
const BASE_CHANNEL_ADLINKS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_ADLINKS}`;

//API_LICENSE
const BASE_CHANNEL_LICENSE =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_LICENSE}`;

//API_DOWNLOADS
const BASE_CHANNEL_DOWNLOADS =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_DOWNLOADS}`;

//API_PRODUCTS_TEMP
const BASE_CHANNEL_PRODUCTS_TEMP =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_PRODUCTS_TEMP}`;

//API_VOTE
const BASE_CHANNEL_VOTE = `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_VOTE}`;

//API_SMALLPACKAGING
const BASE_CHANNEL_SMALLPACKAGING =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_SMALLPACKAGING}`;

//API_EVENTPAGES
const BASE_CHANNEL_EVENTPAGES =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_EVENTPAGES}`;

//API_MARKETING
const BASE_CHANNEL_MARKETING =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_MARKETING}`;

//API_COUPON
const BASE_CHANNEL_COUPON =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_COUPON}`;

// API_FILES
const BASE_COMMON_FILES = `${BASE_URL}` + `${CHANNEL_COMMON}` + `${API_FILES}`;

// API_COMMENT
const BASE_CHANNEL_COMMENT =
  `${BASE_URL}` + `${CHANNEL_STORE}` + `${API_COMMENT}`;

export default {
  PRODUCTS: `${BASE_CHANNEL_PRODUCTS}`,
  SITE_INFO: `${BASE_CHANNEL_SITEINFO}`,
  MEMBER: `${BASE_CHANNEL_MEMBER}`,
  CATEGORY: `${BASE_CHANNEL_CATEGORY}`,
  BASKETS: `${BASE_CHANNEL_BASKETS}`,
  CERTIFICATION: `${BASE_CHANNEL_CERTIFICATION}`,
  FAVORITES: `${BASE_CHANNEL_FAVORITES}`,
  ORDER: `${BASE_CHANNEL_ORDER}`,
  BOARDS: `${BASE_CHANNEL_BORADS}`,
  ALLIANCE: `${BASE_CHANNEL_ALLIANCE}`,
  CORPORATION: `${BASE_CHANNEL_CORPORATION}`,
  PERSON: `${BASE_CHANNEL_PERSON}`,
  MEMBERADDRESS: `${BASE_CHANNEL_MEMBERADDRESS}`,
  VISITS: `${BASE_CHANNEL_VISITS}`,
  DUMMY: `${BASE_CHANNEL_DUMMY}`,
  BANNERS: `${BASE_CHANNEL_BANNERS}`,
  MAKECATEGORYS: `${BASE_CHANNEL_MAKECATEGORYS}`,
  PACKAGINGBASKET: `${BASE_CHANNEL_PACKAGINGBASKET}`,
  PACKAGING: `${BASE_CHANNEL_PACKAGING}`,
  SFNZ: `${SFNZ}`,
  PARTNERS: `${BASE_CHANNEL_PARTNERS}`,
  PORTFOLIO: `${BASE_CHANNEL_PORTFOLIO}`,
  SUBSCRIBE: `${BASE_CHANNEL_SUBSCRIBE}`,
  DELIVERY: `${BASE_CHANNEL_DELIVERY}`,
  RECEIPT: `${BASE_CHANNEL_RECEIPT}`,
  STORE_PRODUCTS_URL: STORE_PRODUCTS_URL,
  COMMON_BOARD: `${BASE_CM_BOARD}`,
  POPUPS: `${BASE_CHANNEL_POPUPS}`,
  GROUPBUYING: `${BASE_CHANNEL_GROUPBUYING}`,
  FCM: `${BASE_CHANNEL_FCM}`,
  LOGS: `${BASE_CHANNEL_LOGS}`,
  ADLINKS: `${BASE_CHANNEL_ADLINKS}`,
  LICENSE: `${BASE_CHANNEL_LICENSE}`,
  DOWNLOADS: `${BASE_CHANNEL_DOWNLOADS}`,
  PRODUCTS_TEMP: `${BASE_CHANNEL_PRODUCTS_TEMP}`,
  VOTE: `${BASE_CHANNEL_VOTE}`,
  SUPPORT: `${SUPPORT_URL}`,
  SMALLPACKAGING: `${BASE_CHANNEL_SMALLPACKAGING}`,
  EVENTPAGES: `${BASE_CHANNEL_EVENTPAGES}`,
  MARKETING: `${BASE_CHANNEL_MARKETING}`,
  COUPON: `${BASE_CHANNEL_COUPON}`,
  COMMON_FILES: `${BASE_COMMON_FILES}`,
  COMMENT: `${BASE_CHANNEL_COMMENT}`,
};
