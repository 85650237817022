import axios from "axios";
import http from "./constants/http";
import resources from "./constants/resources";

// 장바구니 리스트
export const fetchBaskets = async (body) => {
  const res = await http(resources.BASKETS).post(body);

  const resData = {
    code: res?.data?.Code,
    count: res?.data.Data?.Count,
    data: res?.data?.Data.Rows,
    msg: res?.data?.Data?.Msg,
  };

  return resData;
};

// 장바구니 등록
export const insertBaskets = async (body) => {
  const res = await http(resources.BASKETS).post(body);

  const resData = {
    code: res?.data?.Code,
    msg: res?.data?.Data?.Msg,
    data: res?.data?.Data?.Rows,
    count: res?.data?.Data?.Rows?.length,
  };

  return resData;
};

// 장바구니 삭제
export const deleteBasket = async (body) => {
  const res = await http(resources.BASKETS).post(body);

  const resData = {
    code: res?.data?.Code,
    data: res?.data?.Data,
    msg: res?.data?.Data?.Msg,
  };
  return resData;
};

// 장바구니 비우기
export const clearBaskets = async (body) => {
  const res = await http(resources.BASKETS).post(body);

  const resData = {
    code: res?.data?.Code,
    data: res?.data?.Data,
    msg: res?.data?.Data?.Msg,
  };
  return resData;
};

// 장바구니 공통
export const basketsAPI = async (body) => {
  const res = await http(resources.BASKETS).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 장바구니 PDF파일 다운로드
export const basketsPDF = async (body) => {
  const res = await http(resources.BASKETS).postFiles(body);

  return res;
};
