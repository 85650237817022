import * as Types from "../constants/actionTypes";

export default (state = [], action) => {
  switch (action.type) {
    case Types.IMG_ZOOM:
      if (action.payload.imgZoom?.length === 0) {
        return [];
      } else {
        return [...state, ...action.payload.imgZoom];
      }
    default:
      return state;
  }
};
