const getReferrer = () => {
  const currentUrl = window.location.protocol + "//" + window.location.host;

  let referrer = document.referrer;

  if (referrer === currentUrl) {
    let referrer = "";
    return referrer;
  } else {
    return referrer;
  }
};

export default getReferrer;
