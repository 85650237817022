import * as Types from "../constants/actionTypes";

const initialState = { vp: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.USER_VIEW:
      return {
        ...state,
        vp: action.payload.vp,
      };

    default:
      return state;
  }
};
