import Link from "next/link";
function Error({ siteMsg }) {
  return (
    <>
      <main className="page-404">
        <div className="pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                <p className="mb-20">
                  <img
                    src="assets/imgs/page/maintenance.png"
                    alt=""
                    className="hover-up"
                  />
                </p>
                <h1 className="mb-30">{siteMsg}</h1>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Error;
