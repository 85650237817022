import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import SwiperCore, {
  Autoplay,
  Grid,
  Navigation,
  Pagination,
  Scrollbar,
  Thumbs,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { updateImgZoomList } from "../../redux/action/imgZoom";
SwiperCore.use([Navigation, Autoplay, Scrollbar, Pagination, Grid, Thumbs]);

export const ImgSliderWrapper = styled.div`
  .fsImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const FullSizeImgSlider = ({ updateImgZoomList, imgZoomList }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <Modal
        show={imgZoomList?.length > 0 || false}
        centered
        contentClassName="img-zoom-slider-modal"
        onHide={() => updateImgZoomList([])}
      >
        <ModalHeader closeButton />
        <ModalBody>
          {imgZoomList?.length > 0 && (
            <ImgSliderWrapper>
              <Swiper
                style={{ "--swiper-navigation-color": "#3bb77e" }}
                id="fullSizeImgSwiper"
                className="fullSizeImgSwiper"
                navigation={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
              >
                {imgZoomList.map((el, i) => (
                  <SwiperSlide key={i}>
                    <img className="fsImg" src={el.url} alt={el.id} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                slidesPerView={4}
                spaceBetween={20}
                freeMode={true}
                watchSlidesProgress={true}
                slideToClickedSlide={true}
                className="mt-10"
              >
                {imgZoomList.map((el, i) => (
                  <SwiperSlide key={i}>
                    <img
                      className="fsImg"
                      role="button"
                      src={el.url}
                      alt={el.id}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </ImgSliderWrapper>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  imgZoomList: state.imgZoom,
});

const mapDispatchToProps = {
  updateImgZoomList,
};

export default connect(mapStateToProps, mapDispatchToProps)(FullSizeImgSlider);
