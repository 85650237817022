import {
  failAddToCart,
  successAddToCart,
  successAlert,
} from "../../common/alert";
import * as Types from "../constants/actionTypes";

const initialState = {
  code: "",
  items: null,
  count: 0,
  msg: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_BASKETS:
      return {
        ...state,
        items: [...action.payload.baskets],
        count: action.payload.count,
        msg: action.payload.msg,
      };

    case Types.INSERT_BASKETS:
      if (
        state.items?.find((el) => el.pcode === action.payload.product.pcode)
      ) {
        failAddToCart();
        return { ...state, msg: action.payload.msg };
      }
      successAddToCart();
      return {
        ...state,
        items: [...action.payload.baskets],
        count: action.payload.count,
        msg: action.payload.msg,
      };

    case Types.DELETE_BASKETS:
      successAlert("장바구니에서 삭제되었습니다");
      return {
        ...state,
        items: [...action.payload.baskets],
        count: action.payload.count,
        msg: action.payload.msg,
      };

    case Types.CLEAR_BASKETS:
      return {
        ...state,
        items: [...action.payload.baskets],
        count: action.payload.count,
        msg: action.payload.msg,
      };

    case Types.INIT_BASKETS:
      return {
        state: initialState,
      };

    default:
      return state;
  }
};
