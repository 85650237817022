import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Types from "../../redux/constants/actionTypes";
import storage from "../../common/util/localStorage";
import { basketsAPI } from "../../api/baskets";
import { isLoaded, isLoading } from "../../redux/action/loading";

const saveStoredItems = (storedItems) => (dispatch) => {
  dispatch({
    type: Types.INIT_LOCALSTORAGE,
    payload: { ...storedItems },
  });
};

const StorageWrapper = (props) => {
  const [storageData, setStorageData] = useState(null);

  const checkBaskets = async () => {
    const body = {
      modes: "BasketCheck",
      items: JSON.stringify(storageData?.cart?.map((v) => v.pcode) || []),
    };
    const res = await basketsAPI(body);
    if (res?.Code === "0000") {
      const checkedList = res?.Data?.Rows || [];
      props.saveStoredItems({
        cart:
          storageData?.cart?.filter((v) => checkedList.includes(v.pcode)) || [],
      });
    } else {
      props.saveStoredItems({
        cart: storageData?.cart || [],
      });
    }
  };

  useEffect(() => {
    const cart = storage.get("cart");
    setStorageData({ cart });
  }, []);

  useEffect(() => {
    if (storageData) checkBaskets();
  }, [storageData]);

  return <>{props.children}</>;
};
const mapStateToProps = (state) => ({
  cartItems: state.cart,
});
export default connect(mapStateToProps, {
  saveStoredItems,
  isLoaded,
  isLoading,
})(StorageWrapper);
