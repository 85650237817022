import { useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { closeModal } from "../../redux/action/modal";
import modalOverflowHook from "../../common/modalOverflowHook";

const ModalContainer = ({
  show,
  title,
  onAction,
  closeModal,
  bodyContent,
  actionBtnLabel,
  actionBtnActive,
  cancelBtnLabel,
}) => {
  const modalScollHook = modalOverflowHook(show);

  return (
    <Modal
      open={show ? true : false}
      onClose={closeModal}
      showCloseIcon={false}
      closeOnOverlayClick={false}
    >
      <div className="custom-modal-container">
        <header>
          <h2>{title}</h2>
        </header>
        {bodyContent}
        <footer className="modal-footer">
          <button
            onClick={closeModal}
            className="btn btn-heading btn-block hover-up"
          >
            {cancelBtnLabel ? cancelBtnLabel : "cancel"}
          </button>
          {actionBtnActive ? (
            <button className="btn btn-heading btn-block hover-up h-2">
              {actionBtnLabel ? actionBtnLabel : "save"}
            </button>
          ) : (
            <div style={{ display: "none" }} />
          )}
        </footer>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  bodyContent: state.modal.bodyContent,
  show: state.modal.modal,
  title: state.modal.title,
  onAction: state.modal.onAction,
  actionBtnLabel: state.modal.actionBtnLabel,
  actionBtnActive: state.modal.actionBtnActive,
  cancelBtnLabel: state.modal.cancelBtnLabel,
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
