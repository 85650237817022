import axios from "axios";
import { getCookie } from "../../common/auth";
import resources from "./resources";

class Http {
  constructor(url) {
    this.url = url;
  }

  getSessionToken() {
    const sessionToken = sessionStorage.getItem("channel");
    return sessionToken;
  }

  getAccessToken() {
    const accessTokenFromCookie = getCookie("token");
    return accessTokenFromCookie;
  }

  setHeader() {
    const accessToken = this.getAccessToken();
    const sessionToken = this.getSessionToken();
    if (sessionToken) {
      const headers = {
        headers: { token: accessToken, channel: sessionToken },
      };
      return headers;
    }

    const headers = { headers: { token: accessToken } };
    return headers;
  }

  setFilesHeader() {
    const accessToken = this.getAccessToken();
    const headers = {
      headers: { token: accessToken },
      responseType: "arraybuffer",
    };
    return headers;
  }

  async postFiles(body) {
    const formData = new FormData();
    const header = this.setFilesHeader();

    for (const key in body) {
      formData.append(key, body[key]);
    }
    try {
      return await axios.post(this.url, formData, header);
    } catch (error) {
      return error;
    }
  }

  async get() {
    const header = this.setHeader();
    try {
      return await axios.get(this.url, header);
    } catch (error) {
      return refineError(error);
    }
  }

  async post(body) {
    const formData = new FormData();
    const errorFormData = new FormData();
    const header = this.setHeader();
    for (const key in body) {
      if (key === "files" && Array.isArray(body[key]) && body[key].length > 0) {
        body[key].forEach((file) => {
          formData.append("files[]", file);
        });
      } else {
        formData.append(key, body[key]);
      }
    }
    try {
      const res = await axios.post(this.url, formData, header);
      if (res.data.Code && res.data.Code === "9999" && res.data.Data) {
        if (
          res.data.Data.Msg !== "토큰 정보 조회 실패" &&
          body.modes !== "Connect"
        ) {
          const errorBody = {
            modes: "InsertSet",
            division: "STORE",
            content: JSON.stringify({
              url: this.url,
              modes: body.modes,
              msg: res.data.Data.Msg || "메세지 없음",
            }),
          };
          for (const key in errorBody) {
            errorFormData.append(key, errorBody[key]);
          }
          const errorRes = await axios.post(
            resources.LOGS,
            errorFormData,
            header
          );
        }
        return res;
      } else {
        return res;
      }
    } catch (error) {
      return error;
    }
  }

  async put(body) {
    const header = this.setHeader();
    try {
      return await axios.put(this.url, body, header);
    } catch (error) {
      return refineError(error);
    }
  }

  async delete() {
    const header = this.setHeader();
    try {
      return await axios.delete(this.url, header);
    } catch (error) {
      return refineError(error);
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (url) => {
  return new Http(url);
};
