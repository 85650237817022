export function detectDevice() {
  const userAgent = window.navigator.userAgent;
  const isMobile = /Mobile/.test(userAgent);

  let deviceType;
  let browserName;

  if (isMobile) {
    deviceType = "Mobile";
    if (/Android/.test(userAgent)) {
      browserName = "Android";
    } else if (/CriOS/.test(userAgent)) {
      browserName = "Chrome for iOS";
    } else if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      browserName = "Safari for iOS";
    } else if (/Windows Phone|IEMobile/.test(userAgent)) {
      browserName = "Internet Explorer for Windows Phone";
    } else if (/Firefox/.test(userAgent)) {
      browserName = "Firefox for mobile";
    } else if (/Opera Mini/.test(userAgent)) {
      browserName = "Opera Mini";
    } else {
      browserName = "Unknown";
    }
  } else {
    deviceType = "PC";
    if (/Firefox/.test(userAgent)) {
      browserName = "Firefox";
    } else if (/Chrome/.test(userAgent)) {
      browserName = "Chrome";
    } else if (/Safari/.test(userAgent)) {
      browserName = "Safari";
    } else if (/Edge/.test(userAgent)) {
      browserName = "Edge";
    } else if (/MSIE|Trident/.test(userAgent)) {
      browserName = "Internet Explorer";
    } else {
      browserName = "Unknown";
    }
  }

  return {
    deviceType,
    browserName,
  };
}
