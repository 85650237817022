import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { closeConfirmModal } from "../../redux/action/confirm";
import { useEffect } from "react";

const ConfirmModal = ({
  show,
  title,
  message,
  onAction,
  actionBtnLabel,
  actionBtnValid,
  cancelBtnLabel,
  closeConfirmModal,
  onClose,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("padding-right");
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={closeConfirmModal}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="md"
        style={{
          zIndex: 10000060,
          backgroundColor: "#00000004",
        }}
      >
        <Modal.Header>
          <Modal.Title
            style={{
              width: "100%",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              wordBreak: "keep-all",
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        {message && (
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {message}
          </Modal.Body>
        )}
        <Modal.Footer
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gap: "10px",
          }}
        >
          <button
            onClick={() => {
              closeConfirmModal();

              onClose && onClose();
            }}
            className="btn btn-heading btn-block hover-up w-100"
            style={{ backgroundColor: "#253d4e" }}
          >
            {cancelBtnLabel}
          </button>
          {actionBtnValid ? (
            <button
              onClick={onAction}
              className="btn btn-heading btn-block hover-up w-100"
            >
              {actionBtnLabel}
            </button>
          ) : (
            <div style={{ display: "none" }} />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  show: state.confirm.modal,
  title: state.confirm.title,
  message: state.confirm.message,
  onAction: state.confirm.onAction,
  actionBtnLabel: state.confirm.actionBtnLabel,
  actionBtnValid: state.confirm.actionBtnValid,
  cancelBtnLabel: state.confirm.cancelBtnLabel,
  onClose: state.confirm.onClose,
});

const mapDispatchToProps = {
  closeConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
