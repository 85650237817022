import http from "./constants/http";
import resources from "./constants/resources";

const sendUserAgent = async (body) => {
  const response = await http(resources.SITE_INFO).post(body);

  const { status } = response;
  const { Code, Data } = response.data;

  if (status === 200) {
    if (Code === "0000") {
      return "Success";
    }
  } else {
    return console.error(Data.Msg);
  }
};

export default sendUserAgent;
