import { toast } from "react-toastify";
import { failAddToCart, successAddToCart } from "../../common/alert";
import storage from "../../common/util/localStorage";
import {
  deleteProduct,
  deleteProductByCode,
  findProductIndexByCode,
  findProductIndexById,
} from "../../common/util/util";
import * as Types from "../constants/actionTypes";

export default (state = [], action) => {
  let index = null;

  switch (action.type) {
    case Types.INIT_LOCALSTORAGE:
      return [...action.payload.cart];

    case Types.ADD_TO_CART:
      index = findProductIndexByCode(state, action.payload.product.pcode);

      if (index !== -1) {
        failAddToCart();

        return [...state];
      } else {
        if (!action.payload.product.cnt) {
          action.payload.product.cnt = 1;
        }
        storage.set("cart", [action.payload.product, ...state]);
        successAddToCart();
        return [action.payload.product, ...state];
      }

    case Types.DELETE_FROM_CART:
      const newCartItems = deleteProductByCode(
        state,
        action.payload.productCode
      );
      storage.set("cart", newCartItems);

      return [...newCartItems];

    case Types.INCREASE_CNT:
      index = findProductIndexByCode(state, action.payload.productCode);
      if (index === -1) return state;

      state[index].cnt += 1;
      storage.set("cart", [...state]);

      return [...state];

    case Types.DECREASE_CNT:
      index = findProductIndexByCode(state, action.payload.productCode);
      if (index === -1) return state;

      const cnt = state[index].cnt;
      if (cnt > 1) state[index].cnt -= 1;
      storage.set("cart", [...state]);

      return [...state];

    case Types.CLEAR_CART:
      storage.set("cart", []);
      return [];

    default:
      return state;
  }
};
