export const getDate = (more) => {
  const newdate = new Date();
  const yyyy = newdate.getFullYear();
  const mm = newdate.getMonth() + 1;
  const dd = newdate.getDate();
  const tt = newdate.getHours();
  const min = newdate.getMinutes();
  const sec = newdate.getSeconds();
  if (more === "more") {
    return `${yyyy}${String(mm).padStart(2, "0")}${String(dd).padStart(
      2,
      "0"
    )}${tt}${min}${sec}`;
  }
  return `${yyyy}${String(mm).padStart(2, "0")}${String(dd).padStart(2, "0")}`;
};

export const getTimeStamp = () => {
  const newDate = new Date();
  return newDate.getTime();
};

export const getNextDate = () => {
  const newdate = new Date();
  const yyyy = newdate.getFullYear();
  const mm = newdate.getMonth() + 1;
  const dd = newdate.getDate();

  const nextdate = new Date();
  nextdate.setFullYear(yyyy);
  nextdate.setMonth(mm - 1);
  nextdate.setDate(dd);
  nextdate.setHours(23);
  nextdate.setMinutes(59);
  nextdate.setSeconds(59);

  return nextdate;
};

export const tranformDate = (date, option) => {
  const newdate = new Date(date);
  const yyyy = newdate.getFullYear();
  const mm = newdate.getMonth() + 1;
  const dd = newdate.getDate();
  const tt = newdate.getHours();
  const min = newdate.getMinutes();
  const sec = newdate.getSeconds();
  if (option === "more") {
    return `${yyyy}${String(mm).padStart(2, "0")}${String(dd).padStart(
      2,
      "0"
    )}${tt}${min}${sec}`;
  }
  return `${yyyy}${String(mm).padStart(2, "0")}${String(dd).padStart(2, "0")}`;
};

export const getTimeGap = (date, option) => {
  const now = new Date();

  const targetDate = new Date(date);

  // 시간 차이 계산 (밀리초 단위)
  const timeDifference = targetDate - now;

  // 밀리초를 일수와 시간으로 변환
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  if (option === "all") {
    return `${
      days > 0 ? days + "일" : ""
    } ${hours}시간 ${minutes}분 ${seconds}초`;
  }

  return days < 0
    ? `${hours}시간 ${minutes}분 ${seconds}초`
    : `${days + "일"} `;
};
