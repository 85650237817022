import * as Types from "../constants/actionTypes";

const initialState = {
  main_top: [],
  main_btm_m: [],
  main_btm_s: [],
  main_news_bg: [],
  main_news_in: [],
  prod_left_btm: [],
  main_side: [],
  mobile_btm: [],
  main_stripe: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_BANNER:
      return {
        ...state,
        ...action.payload.banners,
      };
    default:
      return state;
  }
};
