import * as Types from "../constants/actionTypes";

export const openModal = () => (dispatch) => {
  dispatch({
    type: Types.OPEN_MODAL,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: Types.CLOSE_MODAL,
  });
};

export const modifyModal = (content) => (dispatch) => {
  dispatch({
    type: Types.MODIFY_MODAL,
    payload: { content },
  });
};
