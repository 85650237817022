import { useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-responsive-modal";
import { closeDefaultModal } from "../../redux/action/defaultModal";
import modalOverflowHook from "../../common/modalOverflowHook";
import styled from "styled-components";

export const DefaultModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  word-break: keep-all;
  text-align: center;
  line-height: normal;
`;

export const DefaultBtn = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 43px;
  margin: 0px 15px;
  font-size: 15px;
  vertical-align: middle !important;
  white-space: nowrap;

  @media screen and (max-width: 575px) {
    width: 150px;
    margin: 0px 5px;
  }
`;

const DefaultModal = ({
  show,
  title,
  onAction,
  closeDefaultModal,
  bodyContent,
  actionBtnLabel,
  actionBtnActive,
  cancelBtnLabel,
}) => {
  return (
    <>
      {show && (
        <Modal
          open={show}
          onClose={closeDefaultModal}
          showCloseIcon={false}
          closeOnOverlayClick={false}
        >
          <DefaultModalContent className="custom-modal-container">
            {bodyContent}
            <footer className="">
              <DefaultBtn onClick={closeDefaultModal} className="btn hover-up">
                {cancelBtnLabel ? cancelBtnLabel : "취소"}
              </DefaultBtn>
              {actionBtnActive ? (
                <DefaultBtn className="btn hover-up" onClick={onAction}>
                  {actionBtnLabel ? actionBtnLabel : "확인"}
                </DefaultBtn>
              ) : (
                <div style={{ display: "none" }} />
              )}
            </footer>
          </DefaultModalContent>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  bodyContent: state.defaultModal.bodyContent,
  show: state.defaultModal.show,
  title: state.defaultModal.title,
  onAction: state.defaultModal.onAction,
  actionBtnLabel: state.defaultModal.actionBtnLabel,
  actionBtnActive: state.defaultModal.actionBtnActive,
  cancelBtnLabel: state.defaultModal.cancelBtnLabel,
});

const mapDispatchToProps = {
  closeDefaultModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultModal);
