import * as Types from "../constants/actionTypes";

const initialState = {
  bodyContent: "",
  title: "",
  modal: false,
  onAction: "",
  actionBtnLabel: "",
  cancelBtnLabel: "",
  showCloseIcon: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.OPEN_MODAL:
      return {
        ...state,
        modal: true,
      };

    case Types.CLOSE_MODAL:
      return {
        ...state,
        modal: false,
      };

    case Types.MODIFY_MODAL:
      const { content } = action.payload;
      return { ...content };

    default:
      return state;
  }
};
