import * as Types from "../constants/actionTypes";

export const openDefaultModal = () => (dispatch) => {
  dispatch({
    type: Types.OPEN_DEFAULT_MODAL,
  });
};

export const closeDefaultModal = () => (dispatch) => {
  dispatch({
    type: Types.CLOSE_DEFAULT_MODAL,
  });
};

export const modifyDefaultModal = (content) => (dispatch) => {
  dispatch({
    type: Types.MODIFY_DEFAULT_MODAL,
    payload: { content },
  });
};
