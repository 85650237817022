import * as Types from "../constants/actionTypes";

export const openConfirmModal = () => (dispatch) => {
  dispatch({
    type: Types.OPEN_CONFIRM_MODAL,
  });
};

export const closeConfirmModal = () => (dispatch) => {
  dispatch({
    type: Types.CLOSE_CONFIRM_MODAL,
  });
};

export const modifyConfirmModal = (content) => (dispatch) => {
  dispatch({
    type: Types.MODIFY_CONFIRM_MODAL,
    payload: { content },
  });
};
