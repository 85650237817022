import { toast } from "react-toastify";

export const addToCartAlert = {
  type: toast.TYPE.SUCCESS,
  autoClose: 2500,
  position: toast.POSITION.TOP_CENTER,
  draggablePercent: 60,
};

export const succeedToSubmit = {
  type: toast.TYPE.SUCCESS,
  autoClose: 1500,
  position: toast.POSITION.TOP_CENTER,
  draggablePercent: 60,
};

export const failToSubmit = {
  type: toast.TYPE.ERROR,
  autoClose: 2000,
  position: toast.POSITION.TOP_CENTER,
  draggablePercent: 60,
};

export const successAddToCart = () => {
  toast("장바구니에 추가되었습니다!", {
    type: toast.TYPE.SUCCESS,
    autoClose: 1000,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
  });
};

export const failAddToCart = () => {
  toast("이미 담긴 상품입니다.", {
    type: toast.TYPE.ERROR,
    autoClose: 1000,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
  });
};

export const addToWishListAlert = () => {
  toast("위시리스트에 추가되었습니다!", {
    type: toast.TYPE.SUCCESS,
    autoClose: 1000,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
  });
};

export const deleteWishListAlert = () => {
  toast("위시리스트에서 삭제되었습니다.", {
    type: toast.TYPE.SUCCESS,
    autoClose: 1000,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
  });
};

// 공통 alert
export const successAlert = (msg) => {
  toast(msg, {
    type: toast.TYPE.SUCCESS,
    autoClose: 1500,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
  });
};

export const failAlert = (msg, option) => {
  toast(msg, {
    type: toast.TYPE.ERROR,
    autoClose: 1500,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
    ...option,
  });
};

// 긴 알림
export const LongInfoAlert = (msg) => {
  toast(msg, {
    type: toast.TYPE.INFO,
    autoClose: 3000,
    position: toast.POSITION.TOP_CENTER,
    draggablePercent: 60,
  });
};
