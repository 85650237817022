import http from "./constants/http";
import resources from "./constants/resources";

export const insertVisit = async (body) => {
  const res = await http(resources.VISITS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
