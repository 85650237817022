import * as Types from "../constants/actionTypes";

export const isLoading = () => (dispatch) => {
  dispatch({
    type: Types.IS_LOADING,
  });
};

export const isLoaded = () => (dispatch) => {
  dispatch({
    type: Types.IS_LOADED,
  });
};