export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
export const GA_TAGMANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER;

export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

export const event = ({ action, category, label, value }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const gtmPageview = (url) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};
