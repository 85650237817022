import http from "./constants/http";
import resources from "./constants/resources";

export const getUserInfo = async () => {
  const body = {
    modes: "UserInfo",
  };

  const res = await http(resources.MEMBER).post(body);
  const resData = {
    code: res.data?.Code,
    data: res.data?.Data?.Member,
    uaddress: res.data?.Data?.Address,
    ucompany: res.data?.Data?.Company,
    person: res.data?.Data?.Person?.Rows,
    ...res.data?.Data,
  };

  return resData;
};

export const getAllUserInfo = async () => {
  const body = {
    modes: "UserInfo",
  };

  const res = await http(resources.MEMBER).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
