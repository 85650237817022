import { useEffect } from "react";

const modalOverflowHook = (isOpen) => {
  useEffect(() => {
    const scrollPosition = window.scrollY;
    if (isOpen) {
      sessionStorage.setItem("scrollY", window.scrollY);
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.left = "0";
      document.body.style.right = "0";
    } else {
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("position");
      document.body.style.removeProperty("top");
      document.body.style.removeProperty("left");
      document.body.style.removeProperty("right");
      window.scrollTo(0, Number(sessionStorage.getItem("scrollY")));
      sessionStorage.removeItem("scrollY");
    }
  }, [isOpen]);

  return;
};
export default modalOverflowHook;
