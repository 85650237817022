import * as Types from "../constants/actionTypes";

const initialState = {
  bodyContent: "",
  title: "",
  show: false,
  onAction: "",
  actionBtnLabel: "",
  cancelBtnLabel: "",
  showCloseIcon: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.OPEN_DEFAULT_MODAL:
      return {
        ...state,
        show: true,
      };

    case Types.CLOSE_DEFAULT_MODAL:
      return {
        ...state,
        show: false,
      };

    case Types.MODIFY_DEFAULT_MODAL:
      const { content } = action.payload;
      return { ...content };

    default:
      return state;
  }
};
