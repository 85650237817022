import * as Types from "../constants/actionTypes";

const initialState = {
  title: "",
  modal: false,
  message: "",
  onAction: () => {},
  actionBtnLabel: "",
  actionBtnValid: true,
  cancelBtnLabel: "",
  onClose: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        modal: true,
      };

    case Types.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        modal: false,
      };

    case Types.MODIFY_CONFIRM_MODAL:
      const { content } = action.payload;
      return {
        ...content,
      };

    default:
      return state;
  }
};
