import { deleteProduct, findProductIndexById } from "../../common/util/util";
import * as Types from "../constants/actionTypes";

export default (state = { items: [] }, action) => {
  switch (action.type) {
    case Types.FETCHED_PRODUCT:
      return {
        ...state,
        items: [...action.payload.products],
      };

    case Types.FETCHED_MORE_PRODUCT:
      const mergeAllProducts = [...state.items, ...action.payload.products];
      const limit =
        action.payload.total && mergeAllProducts.length > action.payload.total
          ? mergeAllProducts.splice(0, action.payload.total)
          : mergeAllProducts;

      return {
        ...state,
        items: [...limit],
      };

    case Types.ADD_PRODUCT:
      return {
        ...state,
        items: [...state.items, action.payload],
      };

    case Types.DELETE_PRODUCT:
      return deleteProduct(state, action.payload.pcode);

    case Types.UPDATE_PRODUCT:
      const index = findProductIndexById(state, action.payload.product.pcode);
      state[index] = action.payload.product;

      return { ...state };

    default:
      return state;
  }
};
