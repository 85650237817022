import styled from "styled-components";

export const ImgNotiWrapper = styled.a`
  .img-section {
    display: flex;
    justify-content: center;
  }

  .contents-section {
    display: flex;
    align-items: center;
    padding: 16px;

    .icon {
      width: 1.75rem;
      height: 1.75rem;
      margin-right: 0.75rem;
    }

    & p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
`;

export const TextNotiWrapper = styled.div`
  p {
    font-size: 13px;
    margin-bottom: 0;
  }
`;
